.pricing-section {
    padding: 60px 0;
    /* background-image: '/images/image1.png'; */

    /* Background image for the section */
    background-size: cover;
    background-position: center;
    color: #000000;
    text-align: center;
  }
  
  .pricing-cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background-color: #f0f9ff; /* Dark overlay on the card background image */
    padding-left: 50px;
    padding-top: 00px;
    padding-right: 50px;
    padding-bottom: 50px;
    width: 400px;
    border-radius: 10px;
    text-align: center;
    position:relative;
  }
  
  .plan-title {
    background-color: #dff2ff;
    color: #000;
    padding: 10px;
    font-size: 1.5rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius:15px;
    margin-bottom: 20px;
  }
  
  .plan-price {
    font-size: 50px;
    margin-bottom: 20px;
    color: #005d8f;
    font-weight: bold;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .feature-item {
    font-size: 1rem;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content:left;
  }
  
  .feature-icon {
    margin-right: 10px;
    color: #4e96bc; /* Icon color */
  }
  
  .buy-now-btn {
    background-color: #f0f9ff;
    color: #005d8f;
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .buy-now-btn:hover {
    background-color: #3a79a1; /* Darker shade for hover effect */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .pricing-cards-container {
      flex-direction: column;
      align-items: center;
    }
  
    .pricing-card {
      width: 100%;
      max-width: 400px;
    }
  }
  .middle-card {
    transform: translateY(-40px); /* Move middle card up */
  }
  