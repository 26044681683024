.budget-simulator {
  max-width: none;
  margin: 0 auto;
  padding: 1rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex: 1.5;
}

.budget-input-container {
  text-align: center;
  margin: 1rem 0;
}

.budget-label h2 {
  color: #16423C;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.budget-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.currency-symbol {
  font-size: 1.5rem;
  font-weight: bold;
  color:#16423C;
}

.budget-input {
  padding: 0.8rem;
  font-size: 1.2rem;
  border: 2px solid #16423C;
  border-radius: 8px;
  width: 200px;
  transition: all 0.3s ease;
}

.budget-input:focus {
  outline: none;
  border-color: #16423C;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.3);
}

.budget-subtitle {
  color: #7f8c8d;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.category {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.category:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.category-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.category-label {
  font-size: 1.1rem;
  color: #333;
}

.slider {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  -webkit-appearance: none;
  appearance: none;
  background: #ddd;
  outline: none;
  margin: 10px 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #256f67;
  cursor: pointer;
  transition: background 0.2s;
}

.slider::-webkit-slider-thumb:hover {
  background: #000000;
}

.progress-container {
  height: 12px;
  background: #e9ecef;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 15px;
  position: relative;
}

.progress-bar {
  height: 100%;
  position: relative;
  transition: width 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2)
  );
  animation: shimmer 2s infinite;
}

.progress-tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  background: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  opacity: 0;
  transform: translateX(-50%) translateY(10px);
  transition: all 0.2s ease;
  pointer-events: none;
  white-space: nowrap;
}

.progress-tooltip::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #333;
}

.progress-bar:hover .progress-tooltip {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.progress-bar.overflow {
  position: absolute;
  background: #16423C;
  backdrop-filter: blur(4px);
  animation: slideIn 0.5s ease forwards;
}

.percentage-label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes slideIn {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

/* Milestone markers */
.progress-container::before,
.progress-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.progress-container::before {
  left: 50%;
}

.progress-container::after {
  left: 75%;
}

/* Category styling */
.category.over-budget {
  animation: pulsate 1.5s ease-in-out infinite;
}

/* Animations */
@keyframes pulsate {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.simulate-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 12px 24px;
  background:#6A9C89 ;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.2s;
}

.simulate-button:hover {
  background: #16423C;
}

.alerts {
  background: #ff4444;
  color: white;
  padding: 15px;
  border-radius: 6px;
  margin: 20px 0;
  text-align: center;
}

.rewards {
  background: #00C851;
  color: white;
  padding: 15px;
  border-radius: 6px;
  margin: 20px 0;
  text-align: center;
}

.chart-container {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #1a1a1a;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .budget-simulator {
    padding: 15px;
  }

  .budget-input {
    width: 120px;
  }

  .category {
    padding: 10px;
  }

  .simulate-button {
    width: 100%;
  }

  .chart-container {
    padding: 10px;
  }
}

/* Animation for alert shake */
.alert-shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}

/* Add these new styles */
.services-container {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  /* height: 100vh; */
  overflow: hidden;
}

.info-section {
  flex: 1;
  padding: 1.5rem;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.info-title {
  color: #000000;
  margin-bottom: 1.5rem;
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
}

.highlight {
  background: linear-gradient(120deg, #19ff9720 0%, #17530120 100%);
  padding: 0 10px;
  border-radius: 4px;
}

.info-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
}

.info-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position:center;
  border-bottom: 3px solid #007bff20;
}

.info-description {
  padding: 1.5rem;
}

.info-description h3 {
  color: #000000;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
}

.accent {
  color:#16423C ;
}

.info-description p {
  color: #000000;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.features-container {
  background: #f8f9fa;
  padding: 1.2rem;
  border-radius: 12px;
}

.features-container h4 {
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  color: #000000;
  font-size: 1rem;
  transition: transform 0.2s ease;
}

.features-list li:hover {
  transform: translateX(5px);
}

.feature-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

/* Make sure the layout is responsive */
@media (max-width: 1024px) {
  .services-container {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    padding: 0.5rem;
    gap: 0.5rem;
  }
  
  .info-section {
    flex: none;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .info-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .info-card {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .info-image {
    height: 180px;
  }
  
  .info-description {
    padding: 1rem;
  }
  
  .info-description h3 {
    font-size: 1.3rem;
  }
  
  .features-container {
    padding: 1rem;
  }
  
  .budget-simulator {
    flex: none;
    padding: 0.75rem;
  }
  
  .budget-label h2 {
    font-size: 1.2rem;
  }
  
  .budget-input-container {
    margin: 0.5rem 0;
  }
  
  .chart-container {
    max-height: 250px;
    padding: 10px;
    margin: 10px auto;
  }
  
  .categories {
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .category {
    padding: 8px;
    margin-bottom: 5px;
  }
  
  .simulate-button {
    margin: 10px auto;
    padding: 8px 16px;
  }
  
  .alerts,
  .rewards {
    margin: 10px 0;
    padding: 10px;
  }
}

/* Additional adjustments for even smaller screens */
@media (max-width: 480px) {
  .info-title {
    font-size: 1.5rem;
  }
  
  .info-image {
    height: 150px;
  }
  
  .info-description h3 {
    font-size: 1.2rem;
  }
  
  .features-list li {
    font-size: 0.9rem;
  }
  
  .feature-icon {
    font-size: 1rem;
  }
} 